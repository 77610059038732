import axios from 'axios';
import React, { useEffect, useState } from "react";

export default function ServiceFaq() {
    const [faqs, set_faqs] = useState([]);
    useEffect(() => {
        const getFaqs = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/faq-view`
                );
                set_faqs(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getFaqs();
    }, []);

    const [activeFAQ, setActiveFAQ] = useState(0);

    const toggleFAQ = (index) => {
        setActiveFAQ(activeFAQ === index ? 0 : index);
    };
    return (
        <section className="relative md:mt-12">
            <div className="w-full pb-16 md:pb-[30px] relative z-10">
                <div className="mx-auto theme-container">
                    <div className="relative flex items-center justify-center px-4 py-10 overflow-hidden md:px-0 md:py-20 rounded-3xl">
                        <div className="max-w-[850px] w-full flex justify-center items-center flex-col relative z-10">
                            <h1 className="py-0.5 px-5 bg-purple/5 border-purple/10 border rounded-[30px] font-medium text-blue-seo">
                                FAQs
                            </h1>
                            <h2 className="mt-5 font-semibold text-24 sm:text-48 text-main-black">
                                Asked Questions & Answer
                            </h2>
                            <div className="flex flex-col gap-2.5 w-full mt-5 md:mt-10 p-0 sm:p-5">
                                <div
                                    data-aos="fade-up"
                                    className="flex flex-col gap-2.5 w-full"
                                >
                                    {/* <!-- faq single start  --> */}
                                    {faqs.map((faq, index) => (
                                        <div
                                            key={index}
                                            className={`py-5 cursor-pointer px-2 md:px-9 w-full rounded-[10px] service-faq-toggler overflow-hidden transition-all duration-300 max-h-fit h-fit border border-purple/10 ${activeFAQ === index ? "bg-purple/5" : ""
                                                }`}
                                            name="faq-1"
                                            onClick={() => toggleFAQ(index)}
                                        >
                                            <div className="flex items-center justify-between w-full pointer-events-none h-fit">
                                                <h1 className="font-semibold sm:text-18 text-main-black">
                                                    {faq.question}
                                                </h1>
                                                {activeFAQ === index ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M18 12.5L12 18.5L6 12.5"
                                                            stroke="#794AFF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M18 12.5L12 6.5L6 12.5"
                                                            stroke="#794AFF"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                            {activeFAQ === index && (
                                                <p className="mt-3.5 text-paragraph pointer-events-none h-fit">
                                                    {faq.answer}
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                    {/* <!-- faq single end  --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
