import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function LogoSliderThree() {
	const [global_client_list, set_global_client_list] = useState([]);
	useEffect(() => {
		const getTeamMember = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_BASE_URL}/global-client-view`
				);
				set_global_client_list(response.data.data);
			} catch (error) {
				console.log(error);
			}
		};
		getTeamMember();
	}, []);
	const swiperOptions = {
		modules: [Autoplay],
		slidesPerView: 3,
		spaceBetween: 30,
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		pagination: false,
		navigation: false,
		breakpoints: {
			640: {
				slidesPerView: 4,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 5,
				spaceBetween: 40,
			},
			1024: {
				slidesPerView: 6,
				spaceBetween: 50,
			},
		},
	};
	return (
		<section className="pb-16 md:pb-[130px]">
			<div className="w-full mx-auto border theme-container border-buisness-dark-black/5 rounded-2xl">
				<div className="w-full py-10 md:py-[70px]">
					<h1 className="w-full font-medium text-center text-paragraph">
						We’ve more then 1250+ global clients
					</h1>
					<div className="overflow-hidden  h5-client_slider mt-11">
						<Swiper {...swiperOptions}>
							{/* <!-- Slides --> */}
							{global_client_list.map((item, index) => (
								<SwiperSlide key={index}>
									<div>
										<img src={item.image} alt="Logo" />
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			</div>
		</section>
	);
}
