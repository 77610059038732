import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";

export default function TestimonialThree() {
  const swiperOptions = {
    modules: [EffectFade, Pagination, Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    speed: 3000,
    autoplay: {
      delay: 2500,
    },
    pagination: {
      el: ".h3-testimonial-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".h3-testimonial-next",
      prevEl: ".h3-testimonial-prev",
    },
  };
  return (
    <section id="testimonials" className="bg-white w-full py-16 md:py-[130px]">
      <div className="w-full mx-auto theme-container">
        <div className="grid grid-cols-6 lg:grid-cols-12 gap-y-10">
          <div className="col-span-6">
            <h1 className="border text-purple border-purple/10 py-0.5 px-5 rounded-[30px] w-fit bg-main-gray font-medium">
              Our Testimonials
            </h1>
            <h2 className="text-24 sm:text-48 font-semibold text-black pt-5 max-w-[457px]">
              Customer Say About Our Services
            </h2>
            <p className="pt-5 text-18 text-paragraph">
              We’ve 15m+ Global and Local Happy Customers
            </p>
            <div className="flex flex-col md:flex-row gap-5 md:gap-[30px] mt-5 md:mt-9">
              <div className="bg-main-gray border border-purple/10 rounded-2xl pt-2.5 px-[30px] pb-5 h-fit">
                <div>
                  <p className="font-semibold text-main-black">
                    Avg rating 4.8
                  </p>
                  <div className="flex gap-2.5 pt-2.5">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42625 1.01713C7.07 -0.339042 8.93 -0.339044 9.57375 1.01713L10.6863 3.36094C10.9419 3.89948 11.4361 4.27275 12.0077 4.35911L14.4955 4.73495C15.9349 4.95243 16.5097 6.79163 15.4681 7.84727L13.6679 9.67167C13.2543 10.0909 13.0656 10.6948 13.1632 11.2867L13.5882 13.8628C13.8341 15.3534 12.3293 16.4901 11.0418 15.7864L8.81666 14.5701C8.3054 14.2906 7.6946 14.2906 7.18334 14.5701L4.95821 15.7864C3.67071 16.4901 2.16594 15.3534 2.41183 13.8628L2.83679 11.2867C2.93443 10.6948 2.74569 10.0909 2.33207 9.67167L0.5319 7.84727C-0.509707 6.79164 0.0650626 4.95243 1.50453 4.73496L3.9923 4.35911C4.56391 4.27275 5.05805 3.89948 5.31369 3.36094L6.42625 1.01713Z"
                        fill="#794AFF"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42625 1.01713C7.07 -0.339042 8.93 -0.339044 9.57375 1.01713L10.6863 3.36094C10.9419 3.89948 11.4361 4.27275 12.0077 4.35911L14.4955 4.73495C15.9349 4.95243 16.5097 6.79163 15.4681 7.84727L13.6679 9.67167C13.2543 10.0909 13.0656 10.6948 13.1632 11.2867L13.5882 13.8628C13.8341 15.3534 12.3293 16.4901 11.0418 15.7864L8.81666 14.5701C8.3054 14.2906 7.6946 14.2906 7.18334 14.5701L4.95821 15.7864C3.67071 16.4901 2.16594 15.3534 2.41183 13.8628L2.83679 11.2867C2.93443 10.6948 2.74569 10.0909 2.33207 9.67167L0.5319 7.84727C-0.509707 6.79164 0.0650626 4.95243 1.50453 4.73496L3.9923 4.35911C4.56391 4.27275 5.05805 3.89948 5.31369 3.36094L6.42625 1.01713Z"
                        fill="#794AFF"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42625 1.01713C7.07 -0.339042 8.93 -0.339044 9.57375 1.01713L10.6863 3.36094C10.9419 3.89948 11.4361 4.27275 12.0077 4.35911L14.4955 4.73495C15.9349 4.95243 16.5097 6.79163 15.4681 7.84727L13.6679 9.67167C13.2543 10.0909 13.0656 10.6948 13.1632 11.2867L13.5882 13.8628C13.8341 15.3534 12.3293 16.4901 11.0418 15.7864L8.81666 14.5701C8.3054 14.2906 7.6946 14.2906 7.18334 14.5701L4.95821 15.7864C3.67071 16.4901 2.16594 15.3534 2.41183 13.8628L2.83679 11.2867C2.93443 10.6948 2.74569 10.0909 2.33207 9.67167L0.5319 7.84727C-0.509707 6.79164 0.0650626 4.95243 1.50453 4.73496L3.9923 4.35911C4.56391 4.27275 5.05805 3.89948 5.31369 3.36094L6.42625 1.01713Z"
                        fill="#794AFF"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42625 1.01713C7.07 -0.339042 8.93 -0.339044 9.57375 1.01713L10.6863 3.36094C10.9419 3.89948 11.4361 4.27275 12.0077 4.35911L14.4955 4.73495C15.9349 4.95243 16.5097 6.79163 15.4681 7.84727L13.6679 9.67167C13.2543 10.0909 13.0656 10.6948 13.1632 11.2867L13.5882 13.8628C13.8341 15.3534 12.3293 16.4901 11.0418 15.7864L8.81666 14.5701C8.3054 14.2906 7.6946 14.2906 7.18334 14.5701L4.95821 15.7864C3.67071 16.4901 2.16594 15.3534 2.41183 13.8628L2.83679 11.2867C2.93443 10.6948 2.74569 10.0909 2.33207 9.67167L0.5319 7.84727C-0.509707 6.79164 0.0650626 4.95243 1.50453 4.73496L3.9923 4.35911C4.56391 4.27275 5.05805 3.89948 5.31369 3.36094L6.42625 1.01713Z"
                        fill="#794AFF"
                      />
                    </svg>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.42625 1.01713C7.07 -0.339042 8.93 -0.339044 9.57375 1.01713L10.6863 3.36094C10.9419 3.89948 11.4361 4.27275 12.0077 4.35911L14.4955 4.73495C15.9349 4.95243 16.5097 6.79163 15.4681 7.84727L13.6679 9.67167C13.2543 10.0909 13.0656 10.6948 13.1632 11.2867L13.5882 13.8628C13.8341 15.3534 12.3293 16.4901 11.0418 15.7864L8.81666 14.5701C8.3054 14.2906 7.6946 14.2906 7.18334 14.5701L4.95821 15.7864C3.67071 16.4901 2.16594 15.3534 2.41183 13.8628L2.83679 11.2867C2.93443 10.6948 2.74569 10.0909 2.33207 9.67167L0.5319 7.84727C-0.509707 6.79164 0.0650626 4.95243 1.50453 4.73496L3.9923 4.35911C4.56391 4.27275 5.05805 3.89948 5.31369 3.36094L6.42625 1.01713Z"
                        fill="#794AFF"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="bg-main-gray border border-purple/10 rounded-2xl pt-2.5 px-[30px] pb-5 h-fit">
                <div>
                  <p className="font-semibold text-main-black">
                    5m+ Happy Clients
                  </p>
                  <div className="relative flex mt-4 cursor-pointer">
                    <img
                      src="/assets/images/home-three/testimonials/sm1.webp"
                      alt=""
                      className="w-[35px] h-[35px] object-cover rounded-full overflow-hidden hover:z-10 transition-all duration-300"
                    />
                    <img
                      src="/assets/images/home-three/testimonials/sm2.webp"
                      alt=""
                      className="w-[35px] h-[35px] object-cover rounded-full overflow-hidden relative -left-1 hover:z-10 transition-all duration-300"
                    />
                    <img
                      src="/assets/images/home-three/testimonials/sm3.webp"
                      alt=""
                      className="w-[35px] h-[35px] object-cover rounded-full overflow-hidden relative -left-2 hover:z-10 transition-all duration-300"
                    />
                    <img
                      src="/assets/images/home-three/testimonials/sm4.webp"
                      alt=""
                      className="w-[35px] h-[35px] object-cover rounded-full overflow-hidden relative -left-3 hover:z-10 transition-all duration-300"
                    />
                    <img
                      src="/assets/images/home-three/testimonials/sm5.webp"
                      alt=""
                      className="w-[35px] h-[35px] object-cover rounded-full overflow-hidden relative -left-4 hover:z-10 transition-all duration-300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-6 flex flex-col items-start justify-start relative max-w-[502px]">
            <Swiper
              {...swiperOptions}
              className="w-full h-full overflow-hidden h3-testimonial-slider"
            >
              <SwiperSlide>
                <div className="flex flex-col justify-between ">
                  <div>
                    <div className="flex items-center gap-6">
                      <div className="flex min-w-12 w-12 sm:w-[60px] min-h-12 h-12 sm:h-[60px] rounded-full overflow-hidden">
                        <img
                          className="object-cover w-full"
                          src="/assets/images/home-three/testimonials/1.webp"
                          alt=""
                        />
                      </div>
                      <h1 className="font-semibold text-20 text-main-black">
                        Matthew C. Lansberry,
                        <span className="text-sm font-medium text-paragraph">
                          CEO & Founder
                        </span>
                      </h1>
                    </div>
                    <h1 className="text-20 sm:text-24 text-paragraph pt-[30px]">
                      Working with
                      <span className="font-semibold text-purple">
                        “Quland”
                      </span>
                      was a game-changer for obrand their innovative approach
                      attentions to detail helped us revamp marketing strategy
                      completely results were bey expectations.
                    </h1>
                  </div>
                  <div className="flex items-center justify-between mt-5 md:mt-10">
                    <svg
                      width="54"
                      height="40"
                      viewBox="0 0 54 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M49.5406 4.06287C47.1408 1.36698 44.2282 0 40.8838 0C37.8781 0 35.33 1.07033 33.3098 3.18112C31.3024 5.27842 30.2845 7.88345 30.2845 10.924C30.2845 13.7974 31.3127 16.3578 33.3404 18.5344C35.129 20.4545 37.3822 21.6759 40.0496 22.1736C39.5831 25.7101 36.4568 28.9111 30.7387 31.7003L29.709 32.2026L33.9307 39.9964L34.8837 39.5134C46.976 33.3839 53.1072 24.7214 53.1072 13.7664C53.1072 9.98687 51.9073 6.72226 49.5406 4.06287ZM34.8388 37.062L32.7236 33.1576C39.0843 29.8246 42.3069 25.8181 42.3069 21.2372V20.2564L41.3324 20.146C38.7077 19.849 36.6191 18.8322 34.9474 17.0374C33.2877 15.2557 32.4808 13.2562 32.4808 10.924C32.4808 8.43381 33.2711 6.39791 34.8964 4.69953C36.5086 3.01495 38.4672 2.19605 40.8839 2.19605C43.6124 2.19605 45.9074 3.28432 47.9 5.5229C49.9262 7.79943 50.9111 10.4958 50.9111 13.7663C50.9111 18.7872 49.4973 23.3202 46.7091 27.2392C44.0485 30.9785 40.0582 34.2797 34.8388 37.062Z"
                        fill="#794AFF"
                      />
                      <path
                        d="M19.7738 4.0579C17.3473 1.36532 14.4226 0 11.0807 0C8.07213 0 5.53555 1.0723 3.54187 3.18703C1.5653 5.2835 0.563015 7.88657 0.563015 10.924C0.563015 13.7973 1.59113 16.3577 3.61863 18.5344C5.40351 20.4504 7.62964 21.6706 10.2474 22.1706C9.78658 25.7098 6.68627 28.9124 1.01401 31.7021L0 32.2006L4.1166 40L5.07906 39.5144C17.2262 33.3852 23.3853 24.7223 23.3853 13.7663C23.3852 9.98387 22.17 6.71749 19.7738 4.0579ZM5.04711 37.0583L2.98964 33.1599C9.30416 29.8257 12.5037 25.8182 12.5037 21.2371V20.2585L11.5314 20.1463C8.96052 19.8496 6.89766 18.8327 5.22542 17.0373C3.56573 15.2558 2.75906 13.2561 2.75906 10.924C2.75906 8.4306 3.53782 6.39252 5.13964 4.69362C6.72402 3.01308 8.6675 2.19605 11.0807 2.19605C13.8119 2.19605 16.122 3.28588 18.1422 5.52798C20.1925 7.80328 21.1892 10.4981 21.1892 13.7663C21.1892 18.7864 19.7692 23.3188 16.9683 27.2374C14.2963 30.9756 10.2888 34.2763 5.04711 37.0583Z"
                        fill="#794AFF"
                      />
                    </svg>
                    <div className="flex items-center gap-2 sm:gap-4">
                      <h1 className="font-semibold text-18 text-main-black">
                        Quality Service
                      </h1>
                      <div className="flex gap-1 sm:gap-2.5">
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col justify-between ">
                  <div>
                    <div className="flex items-center gap-6">
                      <div className="flex min-w-12 w-12 sm:w-[60px] min-h-12 h-12 sm:h-[60px] rounded-full overflow-hidden">
                        <img
                          className="object-cover w-full"
                          src="/assets/images/home-three/testimonials/1.webp"
                          alt=""
                        />
                      </div>
                      <h1 className="font-semibold text-20 text-main-black">
                        Matthew C. Lansberry,
                        <span className="text-sm font-medium text-paragraph">
                          CEO & Founder
                        </span>
                      </h1>
                    </div>
                    <h1 className="text-20 sm:text-24 text-paragraph pt-[30px]">
                      Working with
                      <span className="font-semibold text-purple">
                        “Quland”
                      </span>
                      was a game-changer for obrand their innovative approach
                      attentions to detail helped us revamp marketing strategy
                      completely results were bey expectations.
                    </h1>
                  </div>
                  <div className="flex items-center justify-between mt-5 sm:mt-10">
                    <svg
                      width="54"
                      height="40"
                      viewBox="0 0 54 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M49.5406 4.06287C47.1408 1.36698 44.2282 0 40.8838 0C37.8781 0 35.33 1.07033 33.3098 3.18112C31.3024 5.27842 30.2845 7.88345 30.2845 10.924C30.2845 13.7974 31.3127 16.3578 33.3404 18.5344C35.129 20.4545 37.3822 21.6759 40.0496 22.1736C39.5831 25.7101 36.4568 28.9111 30.7387 31.7003L29.709 32.2026L33.9307 39.9964L34.8837 39.5134C46.976 33.3839 53.1072 24.7214 53.1072 13.7664C53.1072 9.98687 51.9073 6.72226 49.5406 4.06287ZM34.8388 37.062L32.7236 33.1576C39.0843 29.8246 42.3069 25.8181 42.3069 21.2372V20.2564L41.3324 20.146C38.7077 19.849 36.6191 18.8322 34.9474 17.0374C33.2877 15.2557 32.4808 13.2562 32.4808 10.924C32.4808 8.43381 33.2711 6.39791 34.8964 4.69953C36.5086 3.01495 38.4672 2.19605 40.8839 2.19605C43.6124 2.19605 45.9074 3.28432 47.9 5.5229C49.9262 7.79943 50.9111 10.4958 50.9111 13.7663C50.9111 18.7872 49.4973 23.3202 46.7091 27.2392C44.0485 30.9785 40.0582 34.2797 34.8388 37.062Z"
                        fill="#794AFF"
                      />
                      <path
                        d="M19.7738 4.0579C17.3473 1.36532 14.4226 0 11.0807 0C8.07213 0 5.53555 1.0723 3.54187 3.18703C1.5653 5.2835 0.563015 7.88657 0.563015 10.924C0.563015 13.7973 1.59113 16.3577 3.61863 18.5344C5.40351 20.4504 7.62964 21.6706 10.2474 22.1706C9.78658 25.7098 6.68627 28.9124 1.01401 31.7021L0 32.2006L4.1166 40L5.07906 39.5144C17.2262 33.3852 23.3853 24.7223 23.3853 13.7663C23.3852 9.98387 22.17 6.71749 19.7738 4.0579ZM5.04711 37.0583L2.98964 33.1599C9.30416 29.8257 12.5037 25.8182 12.5037 21.2371V20.2585L11.5314 20.1463C8.96052 19.8496 6.89766 18.8327 5.22542 17.0373C3.56573 15.2558 2.75906 13.2561 2.75906 10.924C2.75906 8.4306 3.53782 6.39252 5.13964 4.69362C6.72402 3.01308 8.6675 2.19605 11.0807 2.19605C13.8119 2.19605 16.122 3.28588 18.1422 5.52798C20.1925 7.80328 21.1892 10.4981 21.1892 13.7663C21.1892 18.7864 19.7692 23.3188 16.9683 27.2374C14.2963 30.9756 10.2888 34.2763 5.04711 37.0583Z"
                        fill="#794AFF"
                      />
                    </svg>
                    <div className="flex items-center gap-2 sm:gap-4">
                      <h1 className="font-semibold text-18 text-main-black">
                        Quality Service
                      </h1>
                      <div className="flex gap-1 sm:gap-2.5">
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col justify-between ">
                  <div>
                    <div className="flex items-center gap-6">
                      <div className="flex min-w-12 w-12 sm:w-[60px] min-h-12 h-12 sm:h-[60px] rounded-full overflow-hidden">
                        <img
                          className="object-cover w-full"
                          src="/assets/images/home-three/testimonials/1.webp"
                          alt=""
                        />
                      </div>
                      <h1 className="font-semibold text-20 text-main-black">
                        Matthew C. Lansberry,
                        <span className="text-sm font-medium text-paragraph">
                          CEO & Founder
                        </span>
                      </h1>
                    </div>
                    <h1 className="text-20 sm:text-24 text-paragraph pt-[30px]">
                      Working with
                      <span className="font-semibold text-purple">
                        “Quland”
                      </span>
                      was a game-changer for obrand their innovative approach
                      attentions to detail helped us revamp marketing strategy
                      completely results were bey expectations.
                    </h1>
                  </div>
                  <div className="flex items-center justify-between mt-5 sm:mt-10">
                    <svg
                      width="54"
                      height="40"
                      viewBox="0 0 54 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M49.5406 4.06287C47.1408 1.36698 44.2282 0 40.8838 0C37.8781 0 35.33 1.07033 33.3098 3.18112C31.3024 5.27842 30.2845 7.88345 30.2845 10.924C30.2845 13.7974 31.3127 16.3578 33.3404 18.5344C35.129 20.4545 37.3822 21.6759 40.0496 22.1736C39.5831 25.7101 36.4568 28.9111 30.7387 31.7003L29.709 32.2026L33.9307 39.9964L34.8837 39.5134C46.976 33.3839 53.1072 24.7214 53.1072 13.7664C53.1072 9.98687 51.9073 6.72226 49.5406 4.06287ZM34.8388 37.062L32.7236 33.1576C39.0843 29.8246 42.3069 25.8181 42.3069 21.2372V20.2564L41.3324 20.146C38.7077 19.849 36.6191 18.8322 34.9474 17.0374C33.2877 15.2557 32.4808 13.2562 32.4808 10.924C32.4808 8.43381 33.2711 6.39791 34.8964 4.69953C36.5086 3.01495 38.4672 2.19605 40.8839 2.19605C43.6124 2.19605 45.9074 3.28432 47.9 5.5229C49.9262 7.79943 50.9111 10.4958 50.9111 13.7663C50.9111 18.7872 49.4973 23.3202 46.7091 27.2392C44.0485 30.9785 40.0582 34.2797 34.8388 37.062Z"
                        fill="#794AFF"
                      />
                      <path
                        d="M19.7738 4.0579C17.3473 1.36532 14.4226 0 11.0807 0C8.07213 0 5.53555 1.0723 3.54187 3.18703C1.5653 5.2835 0.563015 7.88657 0.563015 10.924C0.563015 13.7973 1.59113 16.3577 3.61863 18.5344C5.40351 20.4504 7.62964 21.6706 10.2474 22.1706C9.78658 25.7098 6.68627 28.9124 1.01401 31.7021L0 32.2006L4.1166 40L5.07906 39.5144C17.2262 33.3852 23.3853 24.7223 23.3853 13.7663C23.3852 9.98387 22.17 6.71749 19.7738 4.0579ZM5.04711 37.0583L2.98964 33.1599C9.30416 29.8257 12.5037 25.8182 12.5037 21.2371V20.2585L11.5314 20.1463C8.96052 19.8496 6.89766 18.8327 5.22542 17.0373C3.56573 15.2558 2.75906 13.2561 2.75906 10.924C2.75906 8.4306 3.53782 6.39252 5.13964 4.69362C6.72402 3.01308 8.6675 2.19605 11.0807 2.19605C13.8119 2.19605 16.122 3.28588 18.1422 5.52798C20.1925 7.80328 21.1892 10.4981 21.1892 13.7663C21.1892 18.7864 19.7692 23.3188 16.9683 27.2374C14.2963 30.9756 10.2888 34.2763 5.04711 37.0583Z"
                        fill="#794AFF"
                      />
                    </svg>
                    <div className="flex items-center gap-2 sm:gap-4">
                      <h1 className="font-semibold text-18 text-main-black">
                        Quality Service
                      </h1>
                      <div className="flex gap-1 sm:gap-2.5">
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col justify-between">
                  <div>
                    <div className="flex items-center gap-6">
                      <div className="flex min-w-12 w-12 sm:w-[60px] min-h-12 h-12 sm:h-[60px] rounded-full overflow-hidden">
                        <img
                          className="object-cover w-full"
                          src="/assets/images/home-three/testimonials/1.webp"
                          alt=""
                        />
                      </div>
                      <h1 className="font-semibold text-20 text-main-black">
                        Matthew C. Lansberry,
                        <span className="text-sm font-medium text-paragraph">
                          CEO & Founder
                        </span>
                      </h1>
                    </div>
                    <h1 className="text-20 sm:text-24 text-paragraph pt-[30px]">
                      Working with
                      <span className="font-semibold text-purple">
                        “Quland”
                      </span>
                      was a game-changer for obrand their innovative approach
                      attentions to detail helped us revamp marketing strategy
                      completely results were bey expectations.
                    </h1>
                  </div>
                  <div className="flex items-center justify-between mt-5 sm:mt-10">
                    <svg
                      width="54"
                      height="40"
                      viewBox="0 0 54 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M49.5406 4.06287C47.1408 1.36698 44.2282 0 40.8838 0C37.8781 0 35.33 1.07033 33.3098 3.18112C31.3024 5.27842 30.2845 7.88345 30.2845 10.924C30.2845 13.7974 31.3127 16.3578 33.3404 18.5344C35.129 20.4545 37.3822 21.6759 40.0496 22.1736C39.5831 25.7101 36.4568 28.9111 30.7387 31.7003L29.709 32.2026L33.9307 39.9964L34.8837 39.5134C46.976 33.3839 53.1072 24.7214 53.1072 13.7664C53.1072 9.98687 51.9073 6.72226 49.5406 4.06287ZM34.8388 37.062L32.7236 33.1576C39.0843 29.8246 42.3069 25.8181 42.3069 21.2372V20.2564L41.3324 20.146C38.7077 19.849 36.6191 18.8322 34.9474 17.0374C33.2877 15.2557 32.4808 13.2562 32.4808 10.924C32.4808 8.43381 33.2711 6.39791 34.8964 4.69953C36.5086 3.01495 38.4672 2.19605 40.8839 2.19605C43.6124 2.19605 45.9074 3.28432 47.9 5.5229C49.9262 7.79943 50.9111 10.4958 50.9111 13.7663C50.9111 18.7872 49.4973 23.3202 46.7091 27.2392C44.0485 30.9785 40.0582 34.2797 34.8388 37.062Z"
                        fill="#794AFF"
                      />
                      <path
                        d="M19.7738 4.0579C17.3473 1.36532 14.4226 0 11.0807 0C8.07213 0 5.53555 1.0723 3.54187 3.18703C1.5653 5.2835 0.563015 7.88657 0.563015 10.924C0.563015 13.7973 1.59113 16.3577 3.61863 18.5344C5.40351 20.4504 7.62964 21.6706 10.2474 22.1706C9.78658 25.7098 6.68627 28.9124 1.01401 31.7021L0 32.2006L4.1166 40L5.07906 39.5144C17.2262 33.3852 23.3853 24.7223 23.3853 13.7663C23.3852 9.98387 22.17 6.71749 19.7738 4.0579ZM5.04711 37.0583L2.98964 33.1599C9.30416 29.8257 12.5037 25.8182 12.5037 21.2371V20.2585L11.5314 20.1463C8.96052 19.8496 6.89766 18.8327 5.22542 17.0373C3.56573 15.2558 2.75906 13.2561 2.75906 10.924C2.75906 8.4306 3.53782 6.39252 5.13964 4.69362C6.72402 3.01308 8.6675 2.19605 11.0807 2.19605C13.8119 2.19605 16.122 3.28588 18.1422 5.52798C20.1925 7.80328 21.1892 10.4981 21.1892 13.7663C21.1892 18.7864 19.7692 23.3188 16.9683 27.2374C14.2963 30.9756 10.2888 34.2763 5.04711 37.0583Z"
                        fill="#794AFF"
                      />
                    </svg>
                    <div className="flex items-center gap-2 sm:gap-4">
                      <h1 className="font-semibold text-18 text-main-black">
                        Quality Service
                      </h1>
                      <div className="flex gap-1 sm:gap-2.5">
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                            fill="#FF8B33"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="absolute w-fit mx-auto flex items-center -bottom-[70px]">
              <div className="absolute flex justify-between w-full">
                <button className="group h3-testimonial-prev border border-purple/15 w-[30px] h-[30px] rounded-full flex items-center justify-center bg-purple/10 overflow-hidden before:inline-block before:w-11 before:h-11 before:border-[1.5px] before:border-purple before:bg-purple relative before:absolute before:z-0 before:-right-12 hover:before:right-0 before:transition-all before:duration-300">
                  <svg
                    className="relative z-10 pointer-events-none"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="stroke-purple group-hover:stroke-white"
                      d="M4.75 9L1 5.25M1 5.25L4.75 1.5M1 5.25L11 5.25"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button className="group rotate-180 h3-testimonial-next border border-purple/15 w-[30px] h-[30px] rounded-full flex items-center justify-center bg-purple/10 overflow-hidden before:inline-block before:w-11 before:h-11 before:border-[1.5px] before:border-purple before:bg-purple relative before:absolute before:z-0 before:-right-12 hover:before:right-0 before:transition-all before:duration-300">
                  <svg
                    className="relative z-10 pointer-events-none"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="stroke-purple group-hover:stroke-white"
                      d="M4.75 9L1 5.25M1 5.25L4.75 1.5M1 5.25L11 5.25"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="px-10 h3-testimonial-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
