import React from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function PortfolioTestimonial() {
  const swiperOptions = {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    speed: 3000,
    autoplay: {
      delay: 2500,
    },
    pagination: {
      el: ".h5-testimonial-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".h5-testimonial-next",
      prevEl: ".h5-testimonial-prev",
    },
  };
  return (
    <section id="testimonials" className="relative w-full">
      <img
        src="/assets/images/home-ten/dot-shape.svg"
        alt=""
        className="absolute z-0 square-moving-anim"
      />
      <img
        src="/assets/images/home-ten/dot-shape.svg"
        alt=""
        className="absolute right-0 z-0 square-moving-anim-rev"
      />
      <div className="relative z-10 mx-auto theme-container">
        <div className="flex flex-col items-start justify-start relative max-w-[1013px] p-5 md:p-[130px] xl:p-5 2xl:p-[130px] w-full mx-auto">
          <h1 className="font-medium text-white border border-white/10 bg-white/5 px-5 rounded-[30px] py-1 w-fit mx-auto">
            Our Success Story
          </h1>
          <h2 className="w-full pt-5 font-semibold text-center text-white text-24 sm:text-48">
            Our Customer Feedback
          </h2>
          <div className="relative flex flex-col items-center w-full h-full">
            <div className="w-full pb-10 h5-testimonial-slider">
              <Swiper {...swiperOptions}>
                <SwiperSlide>
                  <div className="flex flex-col justify-between ">
                    <div className="relative">
                      <div className="flex flex-col items-center pt-7 md:pt-14">
                        <svg
                          width="60"
                          height="60"
                          viewBox="0 0 60 60"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M55.9702 11.994C53.2589 8.94822 49.9682 7.40381 46.1898 7.40381C42.7939 7.40381 39.9151 8.61307 37.6326 10.9978C35.3647 13.3674 34.2147 16.3105 34.2147 19.7458C34.2147 22.9921 35.3763 25.8849 37.6672 28.3439C39.688 30.5133 42.2336 31.8932 45.2472 32.4556C44.7202 36.4511 41.1881 40.0676 34.7278 43.2188L33.5645 43.7863L38.3341 52.5918L39.4108 52.046C53.0727 45.1209 59.9997 35.334 59.9997 22.957C59.9997 18.687 58.6441 14.9986 55.9702 11.994ZM39.3601 49.2764L36.9704 44.8652C44.1567 41.0997 47.7976 36.5731 47.7976 31.3976V30.2895L46.6966 30.1648C43.7312 29.8292 41.3715 28.6804 39.4828 26.6527C37.6077 24.6397 36.6961 22.3806 36.6961 19.7458C36.6961 16.9323 37.5889 14.6322 39.4252 12.7133C41.2467 10.8101 43.4595 9.8849 46.1899 9.8849C49.2725 9.8849 51.8654 11.1144 54.1167 13.6436C56.4058 16.2156 57.5186 19.262 57.5186 22.9569C57.5186 28.6296 55.9213 33.7509 52.7711 38.1786C49.7653 42.4033 45.257 46.133 39.3601 49.2764Z"
                            fill="white"
                          />
                          <path
                            d="M22.3404 11.9886C19.599 8.94653 16.2947 7.40399 12.519 7.40399C9.11988 7.40399 6.25406 8.61548 4.0016 11.0047C1.76848 13.3733 0.636094 16.3142 0.636094 19.7459C0.636094 22.9922 1.79766 25.8849 4.08832 28.3441C6.10488 30.5088 8.61996 31.8874 11.5775 32.4524C11.0569 36.4509 7.55414 40.0692 1.14562 43.221L0 43.7842L4.65094 52.596L5.73832 52.0473C19.4621 45.1226 26.4207 35.3352 26.4207 22.9571C26.4206 18.6838 25.0477 14.9934 22.3404 11.9886ZM5.70223 49.2724L3.3777 44.868C10.5118 41.101 14.1267 36.5734 14.1267 31.3977V30.292L13.0282 30.1652C10.1236 29.8301 7.79297 28.6811 5.90367 26.6527C4.02855 24.6399 3.11719 22.3807 3.11719 19.7459C3.11719 16.9289 3.99703 14.6263 5.80676 12.7068C7.5968 10.8082 9.79254 9.88509 12.519 9.88509C15.6047 9.88509 18.2146 11.1164 20.497 13.6495C22.8135 16.2201 23.9395 19.2648 23.9395 22.9571C23.9395 28.6289 22.3352 33.7495 19.1708 38.1767C16.152 42.4002 11.6243 46.1293 5.70223 49.2724Z"
                            fill="white"
                          />
                        </svg>

                        <div className="flex gap-4 items-center pt-[18px]">
                          <h1 className="font-semibold text-white text-18">
                            Quality Service
                          </h1>
                          <div className="flex gap-2.5">
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <h1 className="text-18 sm:text-24 text-white pt-[30px] text-center">
                        In this ever-evolving digital era understand the
                        significance of staying ahead through our blog invite
                        explore the dynamic world of IT with us decoding
                        algorithms to unraveling
                      </h1>
                      <div className="flex items-center justify-center gap-6 pt-10">
                        <div className="flex w-[60px] h-[60px] rounded-full overflow-hidden">
                          <img
                            className="object-cover w-full"
                            src="/assets/images//home-five/client-sm.webp"
                            alt=""
                          />
                        </div>
                        <h1 className="font-semibold text-white text-18 sm:text-20">
                          Matthew C. Lansberry,
                          <p className="text-sm font-medium">CEO & Founder</p>
                        </h1>
                      </div>
                      <div className="absolute z-10 right-10 bottom-5 moving-h2-hero-main-img">
                        <img
                          src="/assets/images/home-five/testimonial-shape.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col justify-between ">
                    <div className="relative">
                      <div className="flex flex-col items-center pt-7 md:pt-14">
                        <svg
                          width="60"
                          height="60"
                          viewBox="0 0 60 60"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M55.9702 11.994C53.2589 8.94822 49.9682 7.40381 46.1898 7.40381C42.7939 7.40381 39.9151 8.61307 37.6326 10.9978C35.3647 13.3674 34.2147 16.3105 34.2147 19.7458C34.2147 22.9921 35.3763 25.8849 37.6672 28.3439C39.688 30.5133 42.2336 31.8932 45.2472 32.4556C44.7202 36.4511 41.1881 40.0676 34.7278 43.2188L33.5645 43.7863L38.3341 52.5918L39.4108 52.046C53.0727 45.1209 59.9997 35.334 59.9997 22.957C59.9997 18.687 58.6441 14.9986 55.9702 11.994ZM39.3601 49.2764L36.9704 44.8652C44.1567 41.0997 47.7976 36.5731 47.7976 31.3976V30.2895L46.6966 30.1648C43.7312 29.8292 41.3715 28.6804 39.4828 26.6527C37.6077 24.6397 36.6961 22.3806 36.6961 19.7458C36.6961 16.9323 37.5889 14.6322 39.4252 12.7133C41.2467 10.8101 43.4595 9.8849 46.1899 9.8849C49.2725 9.8849 51.8654 11.1144 54.1167 13.6436C56.4058 16.2156 57.5186 19.262 57.5186 22.9569C57.5186 28.6296 55.9213 33.7509 52.7711 38.1786C49.7653 42.4033 45.257 46.133 39.3601 49.2764Z"
                            fill="white"
                          />
                          <path
                            d="M22.3404 11.9886C19.599 8.94653 16.2947 7.40399 12.519 7.40399C9.11988 7.40399 6.25406 8.61548 4.0016 11.0047C1.76848 13.3733 0.636094 16.3142 0.636094 19.7459C0.636094 22.9922 1.79766 25.8849 4.08832 28.3441C6.10488 30.5088 8.61996 31.8874 11.5775 32.4524C11.0569 36.4509 7.55414 40.0692 1.14562 43.221L0 43.7842L4.65094 52.596L5.73832 52.0473C19.4621 45.1226 26.4207 35.3352 26.4207 22.9571C26.4206 18.6838 25.0477 14.9934 22.3404 11.9886ZM5.70223 49.2724L3.3777 44.868C10.5118 41.101 14.1267 36.5734 14.1267 31.3977V30.292L13.0282 30.1652C10.1236 29.8301 7.79297 28.6811 5.90367 26.6527C4.02855 24.6399 3.11719 22.3807 3.11719 19.7459C3.11719 16.9289 3.99703 14.6263 5.80676 12.7068C7.5968 10.8082 9.79254 9.88509 12.519 9.88509C15.6047 9.88509 18.2146 11.1164 20.497 13.6495C22.8135 16.2201 23.9395 19.2648 23.9395 22.9571C23.9395 28.6289 22.3352 33.7495 19.1708 38.1767C16.152 42.4002 11.6243 46.1293 5.70223 49.2724Z"
                            fill="white"
                          />
                        </svg>

                        <div className="flex gap-4 items-center pt-[18px]">
                          <h1 className="font-semibold text-white text-18">
                            Quality Service
                          </h1>
                          <div className="flex gap-2.5">
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <h1 className="text-20 sm:text-24 text-white pt-[30px] text-center">
                        In this ever-evolving digital era understand the
                        significance of staying ahead through our blog invite
                        explore the dynamic world of IT with us decoding
                        algorithms to unraveling
                      </h1>
                      <div className="flex items-center justify-center gap-6 pt-10">
                        <div className="flex w-[60px] h-[60px] rounded-full overflow-hidden">
                          <img
                            className="object-cover w-full"
                            src="/assets/images//home-five/client-sm.webp"
                            alt=""
                          />
                        </div>
                        <h1 className="font-semibold text-white text-18 sm:text-20">
                          Matthew C. Lansberry,
                          <p className="text-sm font-medium">CEO & Founder</p>
                        </h1>
                      </div>
                      <div className="absolute z-10 right-10 bottom-5 moving-h2-hero-main-img">
                        <img
                          src="/assets/images/home-five/testimonial-shape.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="flex flex-col justify-between ">
                    <div className="relative">
                      <div className="flex flex-col items-center pt-7 md:pt-14">
                        <svg
                          width="60"
                          height="60"
                          viewBox="0 0 60 60"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M55.9702 11.994C53.2589 8.94822 49.9682 7.40381 46.1898 7.40381C42.7939 7.40381 39.9151 8.61307 37.6326 10.9978C35.3647 13.3674 34.2147 16.3105 34.2147 19.7458C34.2147 22.9921 35.3763 25.8849 37.6672 28.3439C39.688 30.5133 42.2336 31.8932 45.2472 32.4556C44.7202 36.4511 41.1881 40.0676 34.7278 43.2188L33.5645 43.7863L38.3341 52.5918L39.4108 52.046C53.0727 45.1209 59.9997 35.334 59.9997 22.957C59.9997 18.687 58.6441 14.9986 55.9702 11.994ZM39.3601 49.2764L36.9704 44.8652C44.1567 41.0997 47.7976 36.5731 47.7976 31.3976V30.2895L46.6966 30.1648C43.7312 29.8292 41.3715 28.6804 39.4828 26.6527C37.6077 24.6397 36.6961 22.3806 36.6961 19.7458C36.6961 16.9323 37.5889 14.6322 39.4252 12.7133C41.2467 10.8101 43.4595 9.8849 46.1899 9.8849C49.2725 9.8849 51.8654 11.1144 54.1167 13.6436C56.4058 16.2156 57.5186 19.262 57.5186 22.9569C57.5186 28.6296 55.9213 33.7509 52.7711 38.1786C49.7653 42.4033 45.257 46.133 39.3601 49.2764Z"
                            fill="white"
                          />
                          <path
                            d="M22.3404 11.9886C19.599 8.94653 16.2947 7.40399 12.519 7.40399C9.11988 7.40399 6.25406 8.61548 4.0016 11.0047C1.76848 13.3733 0.636094 16.3142 0.636094 19.7459C0.636094 22.9922 1.79766 25.8849 4.08832 28.3441C6.10488 30.5088 8.61996 31.8874 11.5775 32.4524C11.0569 36.4509 7.55414 40.0692 1.14562 43.221L0 43.7842L4.65094 52.596L5.73832 52.0473C19.4621 45.1226 26.4207 35.3352 26.4207 22.9571C26.4206 18.6838 25.0477 14.9934 22.3404 11.9886ZM5.70223 49.2724L3.3777 44.868C10.5118 41.101 14.1267 36.5734 14.1267 31.3977V30.292L13.0282 30.1652C10.1236 29.8301 7.79297 28.6811 5.90367 26.6527C4.02855 24.6399 3.11719 22.3807 3.11719 19.7459C3.11719 16.9289 3.99703 14.6263 5.80676 12.7068C7.5968 10.8082 9.79254 9.88509 12.519 9.88509C15.6047 9.88509 18.2146 11.1164 20.497 13.6495C22.8135 16.2201 23.9395 19.2648 23.9395 22.9571C23.9395 28.6289 22.3352 33.7495 19.1708 38.1767C16.152 42.4002 11.6243 46.1293 5.70223 49.2724Z"
                            fill="white"
                          />
                        </svg>

                        <div className="flex gap-4 items-center pt-[18px]">
                          <h1 className="font-semibold text-white text-18">
                            Quality Service
                          </h1>
                          <div className="flex gap-2.5">
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.02461 0.953559C6.62812 -0.317851 8.37187 -0.317854 8.97539 0.953557L10.0184 3.15088C10.2581 3.65576 10.7213 4.0057 11.2572 4.08666L13.5895 4.43902C14.939 4.6429 15.4779 6.36716 14.5013 7.35681L12.8137 9.06719C12.4259 9.46019 12.249 10.0264 12.3405 10.5813L12.7389 12.9964C12.9694 14.3938 11.5587 15.4595 10.3517 14.7997L8.26562 13.6595C7.78631 13.3975 7.21369 13.3975 6.73438 13.6595L4.64832 14.7997C3.44129 15.4595 2.03057 14.3938 2.26109 12.9964L2.65949 10.5813C2.75103 10.0264 2.57408 9.46019 2.18631 9.06719L0.498656 7.35681C-0.47785 6.36716 0.0609962 4.6429 1.4105 4.43902L3.74278 4.08666C4.27867 4.0057 4.74192 3.65576 4.98158 3.15088L6.02461 0.953559Z"
                                fill="#FEC461"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <h1 className="text-20 sm:text-24 text-white pt-[30px] text-center">
                        In this ever-evolving digital era understand the
                        significance of staying ahead through our blog invite
                        explore the dynamic world of IT with us decoding
                        algorithms to unraveling
                      </h1>
                      <div className="flex items-center justify-center gap-6 pt-10">
                        <div className="flex w-[60px] h-[60px] rounded-full overflow-hidden">
                          <img
                            className="object-cover w-full"
                            src="/assets/images//home-five/client-sm.webp"
                            alt=""
                          />
                        </div>
                        <h1 className="font-semibold text-white text-18 sm:text-20">
                          Matthew C. Lansberry,
                          <p className="text-sm font-medium">CEO & Founder</p>
                        </h1>
                      </div>
                      <div className="absolute z-10 right-10 bottom-5 moving-h2-hero-main-img">
                        <img
                          src="/assets/images/home-five/testimonial-shape.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="relative flex items-center pt-4 mx-auto w-fit">
              <div className="absolute flex justify-between w-full">
                <button className="group h5-testimonial-prev w-[30px] h-[30px] rounded-full flex items-center justify-center bg-white/10 border-white/20 overflow-hidden before:inline-block before:w-11 before:h-11 before:border-[1.5px] before:border-caribbean-green before:bg-caribbean-green relative before:absolute before:z-0 before:-right-12 hover:before:right-0 before:transition-all before:duration-300">
                  <svg
                    className="relative z-10 pointer-events-none"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="stroke-caribbean-green group-hover:stroke-main-black"
                      d="M4.75 9L1 5.25M1 5.25L4.75 1.5M1 5.25L11 5.25"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button className="group rotate-180 h5-testimonial-next w-[30px] h-[30px] rounded-full flex items-center justify-center bg-white/10 border-white/20 overflow-hidden before:inline-block before:w-11 before:h-11 before:border-[1.5px] before:border-caribbean-green before:bg-caribbean-green relative before:absolute before:z-0 before:-right-12 hover:before:right-0 before:transition-all before:duration-300">
                  <svg
                    className="relative z-10 pointer-events-none"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="stroke-caribbean-green group-hover:stroke-main-black"
                      d="M4.75 9L1 5.25M1 5.25L4.75 1.5M1 5.25L11 5.25"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="px-10 h5-testimonial-pagination h10-testimonial-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
