import axios from 'axios';
import React, { useRef, useEffect, useState } from "react";


export default function ServiceFour() {
	const [activeTab, setActiveTab] = useState(1);
	const mainTabSectionRef = useRef(null);
	const [we_provide, set_we_provide] = useState([]);

	useEffect(() => {
		const getWeProvide = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_BASE_URL}/we-provide-view`
				);

				set_we_provide(response.data.data);
			} catch (error) {
				console.log(error);
			}
		};
		getWeProvide();
	}, []);

	const handleTabClick = (id, index) => {
		setActiveTab(id);

		const targetTab = mainTabSectionRef.current.querySelector(`.tab-${id}`);
		if (targetTab) {
			const scrollAmount = targetTab.clientWidth * index;
			mainTabSectionRef.current.scrollLeft = scrollAmount;
		} else {
			console.warn(`Tab with id ${id} not found.`);
		}
	};

	return (
		<section id="services" className="pt-16 md:pt-[130px]">
			{/* <!-- title  --> */}
			<div className="flex flex-col items-center mx-auto theme-container">
				<h1 className="border text-main-black border-buisness-red/10 py-0.5 px-5 rounded-[30px] w-fit bg-buisness-red/5 font-medium">
					What We Provide
				</h1>
				<h2 className="text-24 sm:text-48 font-semibold text-main-black pt-5 max-w-[692px] text-center">
					Business agency services refer to a range of professional services
					provided
				</h2>
			</div>
			{/* <!-- services --> */}
			<div className="relative mt-10 md:mt-[70px] h-fit">
				<div className="absolute top-0 z-0 w-full bg-white h-1/2"></div>
				<div className="absolute bottom-0 z-0 w-full h-1/2 bg-buisness-light-black"></div>
				<div className="relative z-10 w-full mx-auto theme-container">
					<div className="flex flex-col justify-between gap-5 pt-3 pl-5 pr-5 md:pt-10 lg:pt-0 xl:pl-28 xl:pr-28 bg-buisness-gray lg:flex-row">
						{/* <!-- buttons  --> */}
						{/* <!-- h5-button-active --> */}
						<div className="flex lg:flex-col gap-1 sm:gap-5 lg:w-full max-w-[446px] lg:py-[70px]">
							{we_provide.map((tab, index) => (
								<button
									key={tab.id}
									className={`tab-${tab.id} group md:w-full p-1.5 md:p-3 lg:py-5 rounded-[40px] flex items-center border border-buisness-dark-black/10 bg-white hover:bg-buisness-red transition-all duration-300 hover:shadow-business-red tab_item  h5-service-btn ${activeTab === tab.id ? "active-tab" : ""}`}
									onClick={() => handleTabClick(tab.id, index)}
								>
									<span className="relative z-10 flex items-center gap-3 font-inter font-semibold text-20 text-main-black group-hover:text-white lg:pl-[35px] transition-all ease-in-out duration-300 pointer-events-none">
										<i className={tab.icon}></i>
										<span className="hidden lg:block">{tab.title_short}</span>
									</span>
								</button>
							))}
						</div>
						{/* <!-- elements  --> */}
						{/* <!-- elements --> */}
						<div
							ref={mainTabSectionRef}
							className="max-w-[520px] overflow-x-scroll flex transition-all duration-300 scroll-smooth relative no-scrollbar py-5 sm:py-10 md:py-[70px] main-tab-section w-full"
						>
							{/* Render content dynamically based on activeTab */}
							{we_provide.map((tab) => (
								<div
									key={tab.id}
									className={`relative max-w-full min-w-full tab-${tab.id} ${activeTab === tab.id ? 'block' : 'hidden'}`}
								>
									<i className={tab.icon}></i>
									<div className="relative z-10">
										<h1 className="text-20 sm:text-24 text-main-black font-semibold mt-4 md:mt-[50px]">
											{tab.title_long}
										</h1>
										<p className="mt-4 text-paragraph md:mt-6">
											{tab.description}
										</p>
										<img
											src={tab.image}
											alt={tab.title_short}
											className="object-cover w-full mt-4 md:mt-14 rounded-2xl"
										/>
									</div>
									<div className="absolute top-0 right-0 hidden md:block">
										<img
											src="/assets/images/home-five/tab-content-shape.png"
											alt="Tab Content Shape"
										/>
									</div>
								</div>
							))}
						</div>

					</div>
				</div>
			</div>
		</section>
	);
}
